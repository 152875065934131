//INFO: 화면설명
<script>
import { getLab } from '@/api/labs'
import LoadingVue from '@/components/ui/Loading.vue'

export default {
  components: { LoadingVue },
  data() {
    return {
      majorList: [],
      isLoading: false
    }
  },
  setup() {},
  created() {},
  async mounted() {
    this.majorList = this.$store.getters['labStore/labListSortByDepartment']
    await this.loadLabList()
  },
  unmounted() {},
  methods: {
    async loadLabList() {
      if (this.majorList.length !== 0) return

      this.isLoading = true

      const labList = (await getLab()).items
      this.$store.dispatch('labStore/LoadLabList', labList)

      this.majorList = this.$store.getters['labStore/labListSortByDepartment']

      this.isLoading = false
    },

    itemClickHandler(affiliatedInstitutionName, departmentName) {
      let query = `affiliatedInstitutionName=${affiliatedInstitutionName}`
      if (departmentName) query += `=departmentName=${departmentName}`
      this.$router.push(`/researchField/result?q=${encodeURIComponent(query)}`)
    }
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <section class="wf__major-wrapper">
    <article class="wf__major" v-for="(major, i) in majorList" :key="i">
      <div class="wf__major-head">
        <h4 class="wf__major-head-text" @click="itemClickHandler(major.affiliatedInstitutionName)">
          {{ major.affiliatedInstitutionName }} ({{ major.counts }})
        </h4>
      </div>
      <div class="wf__major-list">
        <div
          class="wf__major-list-item"
          v-for="(departmentName, j) in major.departments"
          :key="j"
          @click="itemClickHandler(major.affiliatedInstitutionName, departmentName[0])"
        >
          {{ departmentName[0] }} ({{ departmentName[1] }})
        </div>
      </div>
    </article>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: var(--wf-text-16);
}
.wf__major-wrapper {
  min-height: 80vh;
  margin-bottom: 4em;
}
.wf__major {
  margin-top: 3em;
  text-align: left;
  border-bottom: 1px solid var(--wf-gray-color);
}

.wf__major-head {
  background-color: var(--wf-misty-color);
}
.wf__major-head-text {
  width: max-content;
  max-width: 100%;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s;
  font-size: var(--wf-text-24);
}
.wf__major-head-text:hover {
  color: var(--wf-secondary-color);
}
.wf__major-head-text,
.wf__major-list {
  padding: 1em;
  padding-left: var(--wf-text-48);
}

.wf__major-list {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(4, 1fr);
}

.wf__major-list-item {
  cursor: pointer;
  transition: all 0.1s;
}
.wf__major-list-item:hover {
  color: var(--wf-gray-color);
}

@media screen and (max-width: 992px) {
  .wf__major-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .wf__major-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .wf__major-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
